const theme = {
  config: {
    useLocalStorage: false,
  },
  colors: {
    text: "#121212",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#FFFFFF",
    secondary: "#FBDF4B",
    light: "#ffffff",
    dark: "#121212",
    lightGrey: "#B2B2B2",
    home: "#FBDF4B",
    "biogas-channel": "#98D801",
    "cogeneration-channel": "#FA4244",
    "biomethane-rng-channel": "#44B8F3",
    modes: {
      home: {
        primary: "#FBDF4B",
      },
      "cogeneration-channel": {
        primary: "#FA4244",
      },
      "biogas-channel": {
        primary: "#98D801",
      },
      "biomethane-rng-channel": {
        primary: "#44B8F3",
      },
    },
  },
  fonts: {
    body: 'Euclid Square, Roboto, Arial, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",  "Helvetica Neue", sans-serif',
    alternative:
      'freight-display-pro, Roboto, Arial, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",  "Helvetica Neue", sans-serif',
    heading:
      'Euclid Square, Roboto, Arial, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64, 96, 128, 192, 256, 512],
  fontSizes: [12, 14, 15, 18, 20, 24, 34, 48, 60],
  fontWeights: {
    body: 400,
    heading: 500,
  },
  letterSpacings: {
    heading: ".25px",
  },
  lineHeights: {
    body: 1.333,
    heading: 1.118,
  },
  sizes: {
    container: 1440,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [7, 8],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      letterSpacing: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [4, 4, 6, 6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      letterSpacing: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5, 5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: [4, 4],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h5: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: [2, 2],
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    p: {
      fontSize: 2,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      letterSpacing: "body",
      mb: 2,
      mt: 4,
    },
    speaker: {
      fontFamily: "alternative",
      fontWeight: "heading",
      fontSize: [2],
      letterSpacing: ".12px",
      lineHeight: "body",
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: "bold",
      color: "dark",
      mb: 1,
      kicker: {
        paddingX: [2],
        fontSize: 0,
        textTransform: "uppercase",
        position: "absolute",
        top: -2,
        left: 2,
        backgroundColor: "light",
        width: "auto",
        // display: "inline-block",
      },
    },
    input: {
      fontFamily: "body",
      fontSize: 2,
      paddingX: [3],
      paddingY: [3],
      borderRadius: "none",
      backgroundColor: "light",
      borderColor: "lightGrey",
      "&:focus": {
        borderColor: "dark",
      },
    },
  },
  layout: {
    container: {
      paddingX: [3, 6],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
    oneSideRight: {
      maxWidth: [
        "calc(((100% - 750px) / 2) + 750px) ",
        "calc(((100% - 970px) / 2) + 970px)",
        "calc(((100% - 1440px) / 2) + 1440px)",
      ],
      mr: ["auto", 0, 0, 0],
      pl: [3, 6],
    },
  },
  buttons: {
    primary: {
      paddingX: [4],
      cursor: "pointer",
      color: "dark",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "primary",
      borderRadius: "lg",
      "&:hover": {
        color: "light",
        backgroundColor: "dark",
      },
    },
    small: {
      fontSize: [1],
      paddingX: [3],
      cursor: "pointer",
      color: "dark",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "primary",
      borderRadius: "lg",
      "&:hover": {
        color: "light",
        backgroundColor: "dark",
      },
    },
    secondary: {
      color: "background",
      backgroundColor: "secondary",
    },
  },
  links: {
    nav: {
      backgroundColor: "light",
      a: {
        color: "dark",
        textDecoration: "none",
      },
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
    badge: {
      smallCategory: {
        backgroundColor: "primary",
        color: "light",
        display: "inline-block",
        px: [3],
        py: ["4px"],
        fontSize: ["10px"],
        fontWeight: "500",
        letterSpacing: "0.07px",
        lineHeight: "1.1111",
        textTransform: "uppercase",
        borderRadius: "10px",
        textDecoration: "none",
      },
      category: {
        backgroundColor: "primary",
        color: "light",
        display: "inline-block",
        px: [4],
        py: [3],
        fontSize: ["12px", "12px", "14px"],
        fontWeight: "700",
        letterSpacing: "0.11px",
        lineHeight: "body",
        whiteSpace: "nowrap",
        textTransform: "uppercase",
        border: "1px solid",
        borderRadius: "lg",
        textDecoration: "none",
      },
      full: {
        backgroundColor: "primary",
        color: "light",
        display: "inline-block",
        px: [4],
        py: [3],
        fontSize: ["12px", "12px", "14px"],
        fontWeight: "700",
        letterSpacing: "0.11px",
        lineHeight: "body",
        whiteSpace: "nowrap",
        textTransform: "uppercase",
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "lg",
        textDecoration: "none",
      },
      fullLight: {
        backgroundColor: "light",
        color: "primary",
        display: "inline-block",
        px: [4],
        py: [3],
        fontSize: ["12px", "12px", "14px"],
        fontWeight: "700",
        letterSpacing: "0.11px",
        lineHeight: "body",
        whiteSpace: "nowrap",
        textTransform: "uppercase",
        border: "1px solid",
        borderRadius: "lg",
        textDecoration: "none",
      },
      fullDark: {
        backgroundColor: "dark",
        color: "light",
        display: "inline-block",
        px: [4],
        py: [3],
        fontSize: ["12px", "12px", "14px"],
        fontWeight: "700",
        letterSpacing: "0.11px",
        lineHeight: "body",
        whiteSpace: "nowrap",
        textTransform: "uppercase",
        borderRadius: "lg",
        textDecoration: "none",
      },
    },
    button: {
      fontSize: [2],
      paddingX: [4],
      paddingY: [3],
      cursor: "pointer",
      color: "dark",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "primary",
      borderRadius: "lg",
      textDecoration: "none",
      "&:hover": {
        color: "light",
        backgroundColor: "dark",
      },
    },
  },
  styles: {
    root: {
      "*": {
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        textShadow: "1px 1px 1px rgba(0,0,0,0.001)",
      },
      input: {
        transition:
          "background-color .2s linear, border-color .2s linear,  color .2s linear",
      },
      "a,button": {
        fontFamily: "heading",
        transition: "all .2s linear",
        textDecoration: "none",
        textDecoration: "underline",
      },
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": { pb: 5 },
    },
  },
}

export default theme
